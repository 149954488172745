import Badge from "./Badge";

export default function ApplicationLogo({ className, ...props }) {
    return (
        <div {...props} className={`flex items-center ${className}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 657.08 152.44" height={35} className="hidden lg:block fill-white">
                <path d="M274.09 50.32h-35.26l10.94-30.21-8.22-4.95h45.35Zm-45.82 15.15h45.35c8.32 0 17.58-6.73 20.58-15.15L307 15.16C310.1 6.74 305.79 0 297.47 0h-45.36c-8.32 0-17.58 6.73-20.57 15.15l-12.9 35.16c-3 8.43 1.3 15.16 9.63 15.16Z"/>
                <path d="M131.33 117.16H49.24l25.48-70.34-19.16-11.53h105.6ZM24.64 152.44h105.6c19.38 0 40.94-15.69 47.91-35.28L208 35.29C215.17 15.69 205.15 0 185.77 0H80.17C60.78 0 39.23 15.69 32.26 35.29l-30 81.87c-7.01 19.59 3 35.28 22.38 35.28Z" className="fill-primary"/>
                <path d="M323.65.01 299.9 65.47h20.11l15.14-41.61 20.11 41.61h20.11L399.21.01h-20.1l-15.15 41.61L343.85.01h-20.2zM411.14.01l-23.76 65.46h60.42l5.51-15.15h-40.3L431.34.01h-20.2zM471.33 40.31l-9.16 25.16h20.2l9.16-25.16L531.37 0h-20.2l-23.84 24.13L480.97 0h-20.11l10.47 40.31zM250.15 116.62h-35.26l3.65-10.1-8.23-5h45.36Zm-44.42-30.21L182 151.88h20.1l7.29-20.11h15.16l12.81 20.11h20.1l-12.81-20.11h5.05c8.33 0 17.58-6.83 20.67-15.15l5.42-15.06c3.09-8.41-1.21-15.15-9.53-15.15ZM285.23 101.56l-18.33 50.32H287l18.19-50.32h35.4l-7.3 20.11h-25.24a11.27 11.27 0 0 0 10.57 15h9.16l-5.51 15.16h20.1l18.33-50.31c3.09-8.42-1.21-15.16-9.54-15.16h-45.35c-8.33 0-17.58 6.74-20.58 15.16ZM368.07 151.88h45.36c8.32 0 17.57-6.74 20.57-15.15l3.65-10h-20.11l-3.65 10h-35.26l10.94-30.21-8.22-5h45.35l-3.65 10h20.11l3.65-10c3.09-8.42-1.21-15.16-9.54-15.16h-45.35c-8.33 0-17.59 6.74-20.58 15.16l-12.9 35.16c-3 8.46 1.31 15.2 9.63 15.2ZM462.73 86.41l-23.76 65.47h20.11l23.84-65.47h-20.19zM494.87 86.41l-23.75 65.47h20.11l15.15-41.62 20.1 41.62h20.11l23.85-65.47h-20.11l-15.15 41.62-20.11-41.62h-20.2zM572.82 151.88h45.36c8.32 0 17.58-6.74 20.57-15.15l9.17-25.16h-35.26l-5.52 15.15h15.15l-3.64 10h-35.26l10.94-30.21-8.23-5h65.46l5.52-15.16h-60.41c-8.32 0-17.58 6.74-20.57 15.16l-12.82 35.16c-3.08 8.41 1.22 15.15 9.54 15.15Z"/>
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 210.5 152.44" height={35} className="lg:hidden fill-white">
                <path d="M131.33 117.16H49.24l25.48-70.34-19.16-11.53h105.6ZM24.64 152.44h105.6c19.38 0 40.94-15.69 47.91-35.28L208 35.29C215.17 15.69 205.15 0 185.77 0H80.17C60.78 0 39.23 15.69 32.26 35.29l-30 81.87c-7.01 19.59 3 35.28 22.38 35.28Z" className="fill-primary"/>
            </svg>


            <Badge className="ml-3 badge-primary">Beta</Badge>
        </div>
    );
}
